<template>
    <div class="weatherview_wrap">
        <ul class="weaterview_top">
            <li class="weaterview_top_item">
                <h2 class="weaterview_time">
                    {{ getTime }}
                </h2>
            </li>
            <li class="weaterview_top_item">
                <h2 class="weaterview_date">{{ getDate }}</h2>
            </li>
        </ul>

        <ul class="wetherview_status">
            <li class="wetherview_status_item">
                <ul class="weatherview_weather_info">
                    <li class="weatherview_weather_info_item1">
                        {{ getTemp }}
                    </li>
                    <li class="weatherview_weather_info_item2">
                        {{ getWeatherText }}
                    </li>
                </ul>
            </li>
            <li class="wetherview_status_item">
                <figure>
                    <img class="weather_icon" :src="`/media/img/ces/${$store.getters['cesweather/getSelectWeatherIcon']}.png`"
                         :alt="$store.getters['cesweather/getSelectWeatherIcon']">
                </figure>
            </li>
        </ul>

        <ul class="weatherview_location_wrap">
            <li class="weatherview_season">
                Season : Winter
            </li>
            <li class="weatherview_location">
                Location : Convention Center Dr, Las Vegas
            </li>
        </ul>

    </div>
</template>
<script>
import { getCurrentWeather } from '@/service/api/weatherApi';
export default {
  name: 'WeatherView',

  data () {
    return {
      timeInterval: null,
      weatherInterval: null,
      currentDate: new Date(),
      months: [
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" }
      ]
    };
  },
  async created () {
    this.getTimeInfo();
    this.timeInterval = setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
    this.getGeolocation();
  },
  async mounted () {
    try {
      const wakeLock = await navigator.wakeLock.request("screen");
      console.log("wakeLock : ", wakeLock);
    } catch (err) {
      // the wake lock request fails - usually system related, such being low on battery
      console.log(`${err.name}, ${err.message}`);
    }
  },

  methods: {
    getTimeInfo () {
      const hours = this.currentDate.getHours();
      const min = this.currentDate.getMinutes();

      const total = hours * 12 * 10 + min;
      if (total >= 721 && total <= 1080) {
        this.$store.commit("cesweather/setWeatherCurrentTime", "아침");
      } else if (total >= 1081 && total <= 2160) {
        this.$store.commit("cesweather/setWeatherCurrentTime", "낮");
      } else if (total >= 2161 && total <= 2520) {
        this.$store.commit("cesweather/setWeatherCurrentTime", "저녁");
      } else {
        this.$store.commit("cesweather/setWeatherCurrentTime", "밤");
      }
    },
    async settingWeatherInfo () {
      try {
        // 라스베가스 위도 : 36.2277, 경도 : -115.264
        this.settingWeather();
      } catch (error) {
        console.error("getCurrentWeather api error : ", error);
      } finally {
        this.weatherInterval = setInterval(this.settingWeather, 60 * 1000);
      }
    },
    async settingWeather () {
      /* this.$store.getters["cesweather/getGeolocation"].latitude, this.$store.getters["cesweather/getGeolocation"].longitude */
      const result = await getCurrentWeather(36.2277, -115.264);
      const { result: weatherResult } = result.data;
      this.getTimeInfo();
      this.$store.commit("cesweather/setIntervalIcon", weatherResult.icon);
      this.$store.commit("cesweather/setOpenWeatherInfo", weatherResult);
    },
    getGeolocation () {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(this.successCallBackGeolocation, this.failCallBackGeolocation);
      }
    },

    successCallBackGeolocation (position) {
      const { coords } = position;
      const { latitude, longitude } = coords;
      this.$store.commit("cesweather/setgeolocationInfo", {
        latitude,
        longitude
      });
      this.settingWeatherInfo();
    },
    failCallBackGeolocation () {
      this.$store.commit("cesweather/setgeolocationInfo", {
        latitude: 36.2277,
        longitude: -115.264
      });
      this.settingWeatherInfo();
    }

  },
  computed: {
    getTime () {
      const ampm = this.currentDate.getHours() > 11 ? 'PM' : 'AM';
      const hours = this.currentDate.getHours() > 12 ? this.currentDate.getHours() - 12 : this.currentDate.getHours();
      const minutes = this.currentDate.getMinutes() < 10 ? `0${this.currentDate.getMinutes()}` : this.currentDate.getMinutes();

      return `${hours}:${minutes} ${ampm}`;
    },
    getDate () {
      const month = this.currentDate.getMonth() + 1;
      const monthText = this.months.find(item => item.value === month).text;
      const date = this.currentDate.getDate();
      const year = this.currentDate.getFullYear();
      return `${monthText} ${date}, ${year}`;
    },
    getTemp () {
      const celsius = (this.$store.getters["cesweather/getOpenWeatherInfo"].temp.toFixed(2) - 273.15).toFixed(2);
      const fahrenheit = (celsius * 1.8) + 32;
      return `${Math.floor(fahrenheit)}°F`;
    },
    getWeatherText () {
      return this.$store.getters["cesweather/getWeatherText"];
    }
  },
  destroyed () {
    clearInterval(this.timeInterval);
    clearInterval(this.weatherInterval);
  }
};
</script>
<style scoped  src="@/assets/css/ces/weather/weatherview.css"></style>
